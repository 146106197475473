import React from "react";
import Layout from "../components/Layout";
import { IntegrationsSection, CtaSection } from "../sections";
import { BlueCurve1, GrayCurve1 } from "../components/Curves";

const IntegrationsPage = () => {
  return (
    <Layout title="ChatIQ | Integrations">
      <IntegrationsSection />
      <BlueCurve1 />
      <CtaSection />
      <GrayCurve1 />
    </Layout>
  );
};

export default IntegrationsPage;
